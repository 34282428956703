import Router from 'next/router'
import { ReactElement, useEffect } from 'react'
import { Wrapper, Button, FancyBox, styled } from 'ui'
import { Layout } from '../components/layout/layout'
import { WelcomeBack } from '../components/accent-text/welcomeBack'
import Image from 'next/image'

import { Sunrise } from '../components/layout/clouds'

const Cloud1 = styled('div', {
	position: 'absolute',
	top: '20vh',
	left: '-10%',
})

const Cloud2 = styled('div', {
	position: 'absolute',
	top: '66vh',
	right: '-10%',
})

const AccentText = styled('div', {
	position: 'absolute',
	top: '-2rem',
	right: '3rem',
	'& svg': {
		position: 'relative',
		zIndex: 1,
		filter: 'drop-shadow(0 0 1rem $colors$background)',
	},
})

export default function Login() {
	return (
		<>
			<Wrapper
				css={{
					maxWidth: '33rem',
					justifyContent: 'center',
					minHeight: '80vh',
				}}>
				<FancyBox>
					<AccentText>
						<WelcomeBack />
					</AccentText>
					<h1>Sign In</h1>
					{/* <Auth
						supabaseClient={supabase}
						appearance={{
							className: {
								// anchor: 'my-awesome-anchor',
								button: Button.className,
								//..
							},
						}}
						providers={['google']}
						theme='default'
					/> */}
				</FancyBox>
			</Wrapper>
			<Cloud1>
				<Image
					src='/img/cloud1.png'
					width={450}
					height={320}
					alt='cloud floating in the background'
				/>
			</Cloud1>
			<Cloud2>
				<Sunrise />
			</Cloud2>
		</>
	)
}

Login.getLayout = (page: ReactElement) => {
	return <Layout>{page}</Layout>
}
